import axios from '@axios'
import { id } from '@/types'

export const createWizard = async (params: any): Promise<any> => {
  const res = await axios.post('/auth/wizards', params)
  return res
}

export const fetchWizardsList = async (params?: any): Promise<any> => {
  const res = await await axios.get('/auth/wizards', { params })
  return res
}

export const fetchWizard = async (id: id): Promise<any> => {
  const res = await axios.get(`/auth/wizards/${id}`)
  return res
}

export const updateWizard = async (params: any): Promise<any> => {
  const res = await axios.put(`/auth/wizards/${params.id}`, params)
  return res
}

export const deleteWizard = async (id: string): Promise<any> => {
  const res = await axios.delete(`/auth/wizards/${id}`)
  return res
}

export const fetchWizardApplications = async (params: any): Promise<any> => {
  const res = await axios.post('/auth/wizards/applications-by-filter', params)
  return res
}
