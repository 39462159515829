const dynamicSort = (property, sortDesc) => {
  let sortOrder = 1
  if (sortDesc) {
    sortOrder = -1
  }
  return (a, b) => {
    // eslint-disable-next-line no-nested-ternary
    const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
    return result * sortOrder
  }
}

export default dynamicSort
