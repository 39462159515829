<template>
  <resize-col
    :height="'auto'"
    :width="groupWidth"
    :slider-width="2"
    class="mt-1"
    :class="{'group-card_sticky': isPined}"
    @isDragging="isColResizing"
    @dragging="onColResizing"
  >
    <b-card
      class="group-card"
      header-class="group-card__header border-bottom"
      no-body
    >
      <template #header>
        <app-collapse class="collapse-header">
          <app-collapse-item
            title
            :is-visible="false"
          >
            <template #header>
              <h5 class="w-100 d-flex align-items-center justify-content-between pb-0 mb-0 handle cursor-move">
                <span>{{ groupName }} ({{ users.length }})</span>
                <div
                  class="px-1 cursor-pointer"
                  @click.stop="toggleGroupPin"
                >
                  <img
                    v-if="isPined"
                    src="@/assets/images/svg/pined.svg"
                    alt=""
                  >
                  <img
                    v-else
                    src="@/assets/images/svg/unpined.svg"
                    alt=""
                  >
                </div>
              </h5>
            </template>
            <div v-if="group.fields">
              <div class="mb-1">
                Group Fields
              </div>
              <b-badge
                v-for="(field, i) in group.fields"
                :key="i"
                pill
                class="mb-1 mr-1 text-break bg-white text-dark border border-dark"
              >
                {{ field.value }}
              </b-badge>
            </div>
          </app-collapse-item>
        </app-collapse>
      </template>

      <draggable
        v-if="!isTableMode"
        :id="groupId"
        :value="users"
        v-bind="dragOptions"
        class="group-container"
        tag="div"
        @update="onUpdate"
        @start="onDragStart"
        @end="onDragEnd"
        @add="onAdd"
        @remove="onRemove"
      >
        <user-item
          v-for="user in users"
          :id="user.user_id"
          :key="user.user_id"
          :user="user"
          :group-id="groupId"
          :class="{isDragging: isDragging && isSelected(user.user_id)}"
        />
      </draggable>

      <div
        v-else
        class="group-container p-0"
      >
        <table class="table table-sticky-header">
          <thead>
            <tr>
              <th
                v-for="(col, index) in tableColumns"
                :key="index"
                scope="col"
                :style="{
                  fontWeight: col === sortBy ? '600' : '400',
                }"
                class="cursor-pointer"
                @click="sortByColumn(col)"
              >
                <span class="d-flex  align-items-center">
                  {{ col }}
                  <span
                    v-if="col"
                    class="d-inline-flex flex-column text-muted ml-1"
                  >
                    <feather-icon
                      icon="ChevronUpIcon"
                      size="9"
                      :class="{'text-dark': col === sortBy && isSortDirDesc}"
                    />
                    <feather-icon
                      icon="ChevronDownIcon"
                      size="9"
                      :class="{'text-dark': col === sortBy && !isSortDirDesc}"
                    />
                  </span>
                </span>
              </th>
            </tr>
          </thead>

          <draggable
            :id="groupId"
            :value="users"
            v-bind="dragOptions"
            tag="tBody"
            :class="{isNotParentTable: isDragging && prentDraggableTable != groupId}"
            @update="onUpdate"
            @add="onAdd"
            @remove="onRemove"
            @start="onDragStart"
            @end="onDragEnd"
          >
            <user-row-item
              v-for="user in users"
              :id="user.user_id"
              :key="user.user_id"
              :user="user"
              :group-id="groupId"
              :class="{isDragging: isDragging && isSelected(user.user_id)}"
            />
          </draggable>
        </table>
        <div
          v-show="isDragging && prentDraggableTable != groupId"
          class="move-here"
        >
          Move here
        </div>
      </div>
    </b-card>
    <feather-icon
      v-if="groupName !== 'Unassigned'"
      icon="XCircleIcon"
      size="20"
      class="remove-group-icon text-muted"
      @click="removeGroup"
    />
  </resize-col>
</template>

<script>
import {
  ResizeCol,
} from 'vue-resizer'
import { mapGetters } from 'vuex'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import {
  BCard,
  BBadge,
} from 'bootstrap-vue'

import draggable from 'vuedraggable'
import UserItem from '@/views/admin/group/group-assign-wizard/kanban-view/group-card/UserItem.vue'
import UserRowItem from '@/views/admin/group/group-assign-wizard/kanban-view/group-card/UserRowItem.vue'
import dynamicSort from '@/helpers/sortBy'

export default {
  name: 'GroupCard',
  components: {
    AppCollapse,
    AppCollapseItem,
    BCard,
    BBadge,

    draggable,
    UserItem,
    UserRowItem,
    ResizeCol,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
    groupName: {
      type: String,
      required: true,
    },
    groupId: {
      type: String,
      required: true,
    },
    users: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dragOptions: {
        group: 'users',
        ghostClass: 'ghost',
        disabled: false,
      },
      sortBy: null,
      isSortDirDesc: true,
      colResizing: false,
      colWidth: 400,
    }
  },
  computed: {
    ...mapGetters({
      selectedUsers: 'app-group-kanban-view/getSelectedUsers',
      allUsers: 'app-group-kanban-view/getAllUsers',
      pinedGroupId: 'app-group-kanban-view/getPinedGroupId',
      tableColumns: 'app-group-kanban-view/getTableColumns',
      isTableMode: 'app-group-kanban-view/getIsTableMode',
      prentDraggableTable: 'app-group-kanban-view/getParentDraggableTable',
    }),
    isDragging: {
      get() {
        return this.$store.getters['app-group-kanban-view/getIsDragging']
      },
      set(val) {
        this.$store.commit('app-group-kanban-view/SET_IS_DRAGGING', val)
      },
    },
    isPined() {
      return this.groupId === this.pinedGroupId
    },
    groupWidth() {
      if (this.groupId === 'unassigned') {
        const getUnassignedColumnSettings = this.$store.getters['app-group-kanban-view/getUnassignedColumnSettings']
        return getUnassignedColumnSettings.width
      }
      const selectedGroupSettings = this.$store.getters['app-group-kanban-view/getSelectedGroupSettings']
      return selectedGroupSettings[this.groupId].width
    },
  },
  methods: {
    isSelected(userId) {
      return this.selectedUsers.findIndex(u => u.userId === userId) !== -1
    },
    onDragStart(evt) {
      this.isDragging = true
      this.$store.commit('app-group-kanban-view/SET_PARENT_DRAGGABLE_TABLE', evt.from.id)
    },
    onDragEnd() {
      this.isDragging = false
      this.$store.commit('app-group-kanban-view/SET_PARENT_DRAGGABLE_TABLE', null)
      this.$store.commit('app-group-kanban-view/CLEAR_SELECTED_USERS')
    },
    onUpdate(e) {
      this.$store.commit('app-group-kanban-view/SET_IS_STATE_CHANGED', true)
      this.$store.commit('app-group-kanban-view/UPDATE_GROUPS_LIST_ON_DRAG_UPDATE', e)
    },
    async onRemove(e) {
      this.$store.commit('app-group-kanban-view/SET_IS_STATE_CHANGED', true)
      this.$store.commit('app-group-kanban-view/UPDATE_GROUPS_LIST_ON_DRAG_REMOVE', e)
      const selectedUsers = this.$store.getters['app-group-kanban-view/getSelectedUsers'].map(user => user.userId)
      const data = {
        user_ids: selectedUsers.length ? selectedUsers : [e.item.id],
      }
      if (e.to.id !== 'unassigned') {
        data.to_group_id = e.to.id
      }
      if (e.from.id !== 'unassigned') {
        data.from_group_id = e.from.id
      }
      this.$store.commit('app-group-kanban-view/SET_CHANGE_LIST', data)
      await this.$store.dispatch('app-group-kanban-view/applyAssignments')
      // await this.$store.dispatch('app-group-kanban-view/fetchWizardApplications')
    },
    onAdd(e) {
      this.$store.commit('app-group-kanban-view/SET_IS_STATE_CHANGED', true)
      this.$store.commit('app-group-kanban-view/UPDATE_GROUPS_LIST_ON_DRAG_ADD', e)
    },
    toggleGroupPin() {
      if (this.isPined) {
        this.$store.commit('app-group-kanban-view/SET_PINED_GROUP_ID', '')
      } else {
        this.$store.commit('app-group-kanban-view/SET_PINED_GROUP_ID', this.groupId)
      }
    },
    sortByColumn(column) {
      this.sortBy = column
      this.isSortDirDesc = !this.isSortDirDesc
      this.users.sort(dynamicSort(this.sortBy, this.isSortDirDesc))
    },
    async removeGroup() {
      await this.$store.dispatch('app-group-kanban-view/removeSelectedGroup', this.groupId)
    },
    isColResizing(val) {
      // update group width on resizing end
      if (!val) {
        this.setGroupWidthSettings()
      }
    },
    onColResizing(val) {
      this.$store.commit('app-group-kanban-view/SET_IS_STATE_CHANGED', true)
      this.colWidth = val
    },
    setGroupWidthSettings() {
      const payload = {
        groupId: this.groupId,
        width: this.colWidth,
      }
      this.$store.commit('app-group-kanban-view/SET_GROUP_WIDTH_SETTINGS', payload)
    },
    checkMove(evt) {
      this.$store.commit('app-group-kanban-view/SET_PARENT_DRAGGABLE_TABLE', evt.from.id)
    },
  },
}
</script>

<style lang="scss" scoped>
.group-card {
  background: #FBFBFC;
  border: 1px solid #D8D6DE;
  border-radius: 8px;
  height: 100%;
  margin: 0 10px 20px;
  &__header {
    // padding: 1.2rem 10px 0;
    padding: 0;
  }

  &_sticky {
    position: sticky;
    left: 0;
    z-index: 10;
  }
}
.collapse-header {
  background: #FBFBFC;
  border-radius: 8px;
  width: 100%;
  padding: 0;
  .card {
    background: transparent;
  }
}
.remove-group-icon{
  position: absolute;
  right: 2px;
  top: 2px;
  cursor: pointer;
  &:hover {
    color: #ea5455 !important;
  }
}
.group-container {
  padding: 0 10px;
  height: 65vh;
  overflow-y: scroll;
  &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-button {
      height: 1px;
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #D8D6DE;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #7367F0;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: darken(#7367F0, 5%);
    }
}
.table th{
  padding: 0.72rem !important;
}
.table-sticky-header>thead>tr>th {
    position: sticky;
    top: 0;
    z-index: 2;
}
</style>

<style lang="scss">
.selected-item {
  border: 1px solid red;
}
.selected {
  background-color: #f89a9c;
  border: solid red 1px;
}
.isDragging {
  opacity: 0.5;
}
.resize_col {
  flex-shrink: 0;
  padding-top: 10px;
  padding-right: 1px;
}
.slider_col {
  z-index: 0;
  background: #ededed;
  &:hover {
    background: #7367f0;
  }
}
.isNotParentTable {
  position: relative;
  height: 65vh;
  width: 100%;
  background: #000;
  opacity: 0;
  td {
    border: none;
  }
}

.move-here {
  position: absolute;
  top: 30%;
  left: 5%;
  width: 90%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #7367f0;
  border-radius: 10px;
  color: #7367f0;
  font-weight: bold;
}
</style>
