<template>
  <b-modal
    id="wizard-filters-modal"
    ref="wizard-filters-modal"
    title="Filters"
    centered
    no-close-on-backdrop
    ok-title="Apply"
    cancel-variant="outline-secondary"
    size="lg"
    class="p-0"
    @ok="applyFilters"
  >
    <b-row>
      <b-col
        cols="3"
      >
        <b-form-group
          label="Logical Operator"
        >
          <b-form-select
            v-model="wizardFilters.logicalOperator"
            :options="logicalOperatorOptions"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <FilterRow
      v-for="(filter, index) in wizardFilters.children"
      :key="index"
      :filter="filter"
      :index="index"
    />
    <!-- Add Button -->
    <div
      class="d-flex align-items-center justify-content-center"
    >
      <b-button
        variant="outline-primary"
        class="d-flex align-items-center justify-content-center"
        @click="addNewFilter"
      >
        <feather-icon
          icon="PlusCircleIcon"
          size="25"
          class="mr-1 cursor-pointer"
        />
        Add filter
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BModal, BButton, BRow, BCol, BFormGroup, BFormSelect,
} from 'bootstrap-vue'

import FilterRow from '@/views/admin/group/group-assign-wizard/kanban-view/wizard-filters-modal/FilterRow.vue'

export default {
  components: {
    BModal,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,

    FilterRow,
  },
  data() {
    return {
      logicalOperatorOptions: [
        { value: 'and', text: 'And' },
        // { value: 'or', text: 'Or' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      wizardFilters: 'app-group-kanban-view/getWizardFilters',
    }),
  },
  methods: {
    addNewFilter() {
      this.$store.commit('app-group-kanban-view/ADD_NEW_FILTER_ROW')
    },
    applyFilters() {
    },
  },
}
</script>

<style>

</style>
