<template>
  <div>
    <wizard-settings />

    <b-card
      no-body
    >
      <!-- Header-->
      <b-card-header>
        <b-col
          md="6"
        >
          <h3>{{ selectedWizardName || 'Group Assignment Wizard' }}</h3>
        </b-col>
        <b-col
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-checkbox
              v-model="isTableMode"
              :unchecked-value="0"
              :value="1"
              switch
            >Table View</b-form-checkbox>
          </div>
        </b-col>
      </b-card-header>

      <b-card-body>
        <Spinner
          v-if="isLoading"
        />

        <div
          v-else
        >
          <draggable
            v-model="groupsList"
            class="groups-list"
            tag="div"
            handle=".handle"
            @end="updateSelectedGroupsOrder"
          >
            <group-card
              v-for="group in groupsList"
              :key="group.id"
              :users="group.users"
              :group="group"
              :group-name="group.name"
              :group-id="group.id"
            />
          </draggable>
        </div>

        <hr>

        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-between"
          >
            <div>
              <b-button
                v-if="wizardId"
                variant="outline-danger"
                @click="deleteWizard"
              >
                <span>Delete Wizard</span>
              </b-button>
            </div>

            <div>
              <b-button
                v-if="wizardId"
                variant="outline-primary"
                :disabled="isSaveButtonDisabled"
                @click="showSaveModal"
              >
                <span>Save</span>
              </b-button>
              <b-button
                class="ml-1"
                variant="primary"
                :disabled="isSaveButtonDisabled"
                @click="showSaveAsModal"
              >
                <span>Save As</span>
              </b-button>
            </div>

          </b-col>
        </b-row>
      </b-card-body>

      <save-wizard-modal />

    </b-card>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { onUnmounted } from '@vue/composition-api'
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue'

import draggable from 'vuedraggable'
import WizardSettings from '@/views/admin/group/group-assign-wizard/kanban-view/wizard-settings/WizardSettings.vue'
import GroupCard from '@/views/admin/group/group-assign-wizard/kanban-view/group-card/GroupCard.vue'
import SaveWizardModal from '@/views/admin/group/group-assign-wizard/kanban-view/save-wizard-modal/SaveWizardModal.vue'
import Spinner from '@/views/components/table-spinner/TableSpinner.vue'
import store from '@/store'
import storeModule from '@/views/admin/group/group-assign-wizard/kanban-view/groupKanbanViewStoreModuleV2'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,

    draggable,

    WizardSettings,
    GroupCard,
    SaveWizardModal,
    Spinner,
  },
  data() {
    return {
      currentRouteName: this.$router.currentRoute.name,
      wizardId: this.$router.currentRoute.params.id,
    }
  },
  computed: {
    ...mapGetters({
      programId: 'verticalMenu/getDefaultProgram',
      isLoading: 'app-group-kanban-view/getIsLoading',
      wizardsList: 'app-group-kanban-view/getWizardsList',
      selectedWizardName: 'app-group-kanban-view/getSelectedWizardName',
      isStateChanged: 'app-group-kanban-view/getIsStateChanged',
      isSaveButtonDisabled: 'app-group-kanban-view/getIsSaveButtonDisabled',
    }),
    groupsList: {
      get() {
        return this.$store.getters['app-group-kanban-view/getGroupsList']
      },
      set(val) {
        this.$store.commit('app-group-kanban-view/SET_IS_STATE_CHANGED', true)
        this.$store.commit('app-group-kanban-view/SET_GROUPS_LIST', val)
      },
    },
    selectedWizard: {
      get() {
        return this.$store.getters['app-group-kanban-view/getSelectedWizard']
      },
      set(val) {
        this.$store.commit('app-group-kanban-view/SET_SELECTED_WIZARD', val)
      },
    },
    isTableMode: {
      get() {
        return this.$store.getters['app-group-kanban-view/getIsTableMode']
      },
      set(val) {
        this.$store.commit('app-group-kanban-view/SET_TABLE_MODE', val)
      },
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-group-kanban-view'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })
  },
  async created() {
    await this.setWizard()
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav)
  },
  beforeRouteLeave(to, from, next) {
    if (this.isStateChanged) {
      // eslint-disable-next-line no-alert
      if (!window.confirm('There are some changes in the Wizard configuration that are not saved. Are you sure you want to refresh/leave the page?')) {
        return
      }
    }
    next()
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventNav)
    this.$store.commit('app-group-kanban-view/RESET_STATE')
  },
  methods: {
    showSaveAsModal() {
      this.$store.commit('app-group-kanban-view/SET_SAVE_MODAL_MODE', 'saveAs')
      this.$bvModal.show('save-wizard-modal')
    },
    showSaveModal() {
      this.$store.commit('app-group-kanban-view/SET_SAVE_MODAL_MODE', 'save')
      this.$bvModal.show('save-wizard-modal')
    },
    async setWizardSettings(val) {
      this.$store.commit('app-group-kanban-view/SET_WIZARD_SETTINGS', val)
      this.$store.commit('app-group-kanban-view/RESET_GROUPS_LIST')
      await this.$store.dispatch('app-group-kanban-view/fetchWizardApplications')
    },
    async applyAssignments() {
      await this.$store.dispatch('app-group-kanban-view/applyAssignments', this)
    },
    async setWizard() {
      if (this.wizardId) {
        const wizard = await this.$store.dispatch('app-group-kanban-view/fetchWizard', this.wizardId)
        if (wizard) {
          this.selectedWizard = wizard
          await this.setWizardSettings(wizard)
        }
      }
    },
    async deleteWizard() {
      await this.$store.dispatch('app-group-kanban-view/deleteWizard', this.selectedWizard.id)
      this.$router.push({ name: 'admin-group-wizard-list' })
    },
    updateSelectedGroupsOrder() {
      this.$store.commit('app-group-kanban-view/UPDATE_SELECTED_GROUPS_ORDER')
    },
    preventNav(event) {
      if (!this.isStateChanged) return
      event.preventDefault()
      // eslint-disable-next-line no-param-reassign
      event.returnValue = ''
    },
  },
}
</script>

<style lang="scss" scopped>
.groups-list {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  min-height: 400px;
}
</style>
