<template>
  <b-card
    class="user-item"
    @click="toggleSelect(groupId, user.user_id)"
  >
    <b-form-checkbox
      :checked="isSelected"
      :value="true"
      class="user-checkbox"
      @change="toggleSelect(groupId, user.user_id)"
    />
    <h5>{{ user.full_name }}</h5>
    <template v-for="(value, key) in user">
      <b-badge
        v-if="!['application_id', 'user_id'].includes(key) && value"
        :id="`field-${user.user_id}${value}`"
        :key="key"
        pill
        variant="light-secondary"
        class="mb-1 mr-1 text-break"
      >
        {{ value | cutString }}
        <b-tooltip
          v-if="value.length > 20"
          :target="`field-${user.user_id}${value}`"
          triggers="hover"
          variant="light"
        >
          {{ value }}
        </b-tooltip>
      </b-badge>
    </template>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BCard,
  BFormCheckbox,
  BBadge,
  BTooltip,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BFormCheckbox,
    BBadge,
    BTooltip,
  },
  filters: {
    cutString(val) {
      let string = val
      if (val) {
        if (string.length > 20) {
          string = `${string.slice(0, 17)} ...`
        }
      }
      return string
    },
  },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    groupId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      selectedUsers: 'app-group-kanban-view/getSelectedUsers',
    }),
    isSelected() {
      return this.selectedUsers.findIndex(u => u.userId === this.user.user_id && u.groupId === this.groupId) !== -1
    },
  },
  methods: {
    toggleSelect(groupId, userId) {
      this.$store.commit('app-group-kanban-view/TOGGLE_SELECTED_USER', { groupId, userId })
    },
  },
}
</script>

<style lang="scss" scoped>
.user-item {
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.12) !important;
  border-radius: 8px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-left: 1.5rem;
}
</style>

<style lang="scss" >
.user-checkbox{
  position: absolute;
  top: calc(50% - 12px);
  left: 0.7rem;
}
</style>
