<template>
  <b-card
    class="mb-1"
  >
    <b-row
      align-v="center"
    >
      <b-col
        cols="1"
      >
        <span>{{ wizardFilters.logicalOperator }}</span>
      </b-col>

      <b-col
        cols="10"
      >
        <b-row>
          <b-col
            cols="6"
          >
            <b-form-group
              label="Type"
            >
              <v-select
                v-model="filter.type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="filterTypes"
                label="text"
                :reduce="option => option.value"
                :clearable="false"
                placeholder="Please select a type"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
          >
            <b-form-group
              label="Field"
            >
              <v-select
                v-model="filter.field"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="fieldsOptions"
                label="label"
                :reduce="option => option.label ? option.label : option"
                :clearable="false"
                placeholder="Please select a field"
                :disabled="!filter.type"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="6"
          >
            <b-form-group
              label="Operator"
            >
              <v-select
                v-model="filter.operator"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="operatorsOptions"
                label="text"
                :reduce="option => option.value"
                :clearable="false"
                placeholder="Please select an operator"
                :disabled="!filter.type"
              />
            </b-form-group>
          </b-col>

          <b-col
            v-if="isDateOperator"
            cols="6"
          >
            <b-form-group
              label="Value"
            >
              <flat-pickr
                v-model="filter.value"
                class="form-control"
              />
            </b-form-group>
          </b-col>

          <b-col
            v-else
            cols="6"
          >
            <b-form-group
              label="Value"
            >
              <b-form-input
                v-model="filter.value"
                :disabled="!filter.type || isOperatorWithoutValue"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>

      <b-col
        cols="1"
        class="d-flex justify-content-end"
      >
        <feather-icon
          icon="Trash2Icon"
          size="25"
          class="cursor-pointer text-primary"
          @click="removeFilterRow"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BFormInput, BFormGroup, BRow, BCol, BCard,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BCol,
    BRow,
    BCard,

    flatPickr,
    vSelect,
  },
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      operatorsOptions: [
        { value: 'equals', text: 'equals' },
        { value: 'does_not_equal', text: 'does not equal' },
        { value: 'greater_than', text: 'greater than' },
        { value: 'greater_than_or_equal', text: 'greater than or equal' },
        { value: 'less_than', text: 'less than' },
        { value: 'less_than_or_equal', text: 'less than or equal' },
        { value: 'like', text: 'like' },
        { value: 'not_like', text: 'not like' },
        { value: 'is_at', text: 'is at' },
        { value: 'is_before', text: 'is before' },
        { value: 'is_before_or_at', text: 'is before or at' },
        { value: 'is_after', text: 'is after' },
        { value: 'is_after_or_at', text: 'is after or at' },
        { value: 'is_not_at', text: 'is not at' },
        { value: 'is_blank', text: 'is blank' },
        { value: 'is_not_blank', text: 'is not blank' },
        { value: 'is_yesterday', text: 'is yesterday' },
        { value: 'is_today', text: 'is today' },
        { value: 'is_this_week', text: 'is this week' },
        { value: 'is_this_month', text: 'is this month' },
        { value: 'is_this_year', text: 'is this year' },
        { value: 'is_last_month', text: 'is last month' },
        { value: 'is_next_month', text: 'is next month' },
      ],
      filterTypes: [
        { text: 'Custom Field', value: 'custom_field' },
        { text: 'Application PPA', value: 'ppa' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      wizardFilters: 'app-group-kanban-view/getWizardFilters',
      groupFieldsList: 'app-group-kanban-view/getGroupFieldsList',
      cardFieldsList: 'app-group-kanban-view/getCardFieldsList',
    }),
    fieldsOptions() {
      return this.filter.type === 'custom_field' ? this.groupFieldsList : this.cardFieldsList
    },
    isDateOperator() {
      return ['is_at', 'is_before', 'is_before_or_at', 'is_after', 'is_after_or_at', 'is_not_at'].includes(this.filter.operator)
    },
    isOperatorWithoutValue() {
      return [
        'is_blank',
        'is_not_blank',
        'is_yesterday',
        'is_today',
        'is_this_week',
        'is_this_month',
        'is_this_year',
        'is_last_month',
        'is_next_month',
      ].includes(this.filter.operator)
    },
  },
  watch: {
    'filter.type': function fn() {
      this.resetFileds()
    },
  },
  methods: {
    removeFilterRow() {
      this.$store.commit('app-group-kanban-view/REMOVE_FILTER_ROW', this.index)
    },
    resetFileds() {
      this.filter.field = ''
      this.filter.operator = ''
      this.filter.value = ''
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
