/* eslint-disable no-param-reassign */
import Vue from 'vue'
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'
import {
  fetchWizard, createWizard, updateWizard, fetchWizardApplications,
} from '@/api/groupWizards'

const getDefaultState = () => ({
  isLoading: false,
  isWizardSettingsLoading: false,
  wizardsList: [],
  selectedWizard: null,
  groupsList: [],
  changeList: null,
  selectedUsers: [],
  isDragging: false,
  pinedGroupId: '',
  isTableMode: 1,
  groupTypesList: [],
  groupTypeGroupsList: [],
  groupFieldsList: [],
  cardFieldsList: [],
  sessionsList: [],
  selectedGroupType: null,
  selectedGroups: [],
  selectedGroupFields: [],
  selectedCardFields: [],
  selectedSessions: [],
  selectedGroupSettings: {},
  unassignedColumnSettings: {
    width: 400,
  },
  wizardFilters: {
    children: [
      {
        type: '',
        field: '',
        operator: '',
        value: '',
      },
    ],
    logicalOperator: 'and',
  },
  saveModalMode: 'save',
  isStateChanged: false,
  parentDraggableTable: null,
  tableColumns: [],
  isSaveButtonDisabled: false,
})

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getIsLoading: state => state.isLoading,
    getIsWizardSettingsLoading: state => state.isWizardSettingsLoading,
    getWizardsList: state => state.wizardsList,
    getSelectedWizard: state => state.selectedWizard,
    getGroupsList: state => state.groupsList,
    getSelectedUsers: state => state.selectedUsers,
    getIsDragging: state => state.isDragging,
    getPinedGroupId: state => state.pinedGroupId,
    getIsTableMode: state => state.isTableMode,
    getGroupTypesList: state => state.groupTypesList,
    getGroupTypeGroupsList: state => state.groupTypeGroupsList,
    getGroupFieldsList: state => state.groupFieldsList,
    getCardFieldsList: state => state.cardFieldsList,
    getSessionsList: state => state.sessionsList,
    getSelectedGroupType: state => state.selectedGroupType,
    getSelectedGroups: state => state.selectedGroups,
    getSelectedGroupFields: state => state.selectedGroupFields,
    getSelectedCardFields: state => state.selectedCardFields,
    getSelectedSessions: state => state.selectedSessions,
    getSelectedGroupSettings: state => state.selectedGroupSettings,
    getUnassignedColumnSettings: state => state.unassignedColumnSettings,
    getWizardFilters: state => state.wizardFilters,
    getSelectedWizardName: state => state.selectedWizard?.name,
    getTableColumns: state => state.tableColumns,
    getAllUsers: state => state.groupsList.reduce((arr, item) => {
      arr.push(...item.users)
      return arr
    }, []),
    getSaveModalMode: state => state.saveModalMode,
    getIsStateChanged: state => state.isStateChanged,
    getParentDraggableTable: state => state.parentDraggableTable,
    getIsSaveButtonDisabled: state => state.isSaveButtonDisabled,
  },
  mutations: {
    SET_IS_LOADING: (state, val) => {
      state.isLoading = val
    },
    SET_IS_WIZARD_SETTINGS_LOADING: (state, val) => {
      state.isWizardSettingsLoading = val
    },
    SET_WIZARDS_LIST: (state, val) => {
      state.wizardsList = val
    },
    SET_SELECTED_WIZARD: (state, val) => {
      state.selectedWizard = val
    },
    SET_TABLE_MODE: (state, val) => {
      state.isTableMode = val
    },
    SET_GROUPS_LIST: (state, val) => {
      state.groupsList = val
    },
    REMOVE_GROUP_FROM_GROUP_LIST: (state, groupId) => {
      const targetGroupIndex = state.groupsList.findIndex(item => item.id === groupId)
      state.groupsList.splice(targetGroupIndex, 1)
    },
    RESET_GROUPS_LIST: state => {
      state.groupsList = []
    },
    SET_IS_DRAGGING: (state, val) => {
      state.isDragging = val
    },
    SET_PINED_GROUP_ID: (state, val) => {
      state.pinedGroupId = val
    },
    SET_GROUP_TYPES_LIST: (state, val) => {
      state.groupTypesList = val
    },
    SET_GROUP_TYPE_GROUPS_LIST: (state, val) => {
      state.groupTypeGroupsList = val
    },
    SET_GROUP_FIELDS_LIST: (state, val) => {
      state.groupFieldsList = val
    },
    SET_CARD_FIELDS_LIST: (state, val) => {
      state.cardFieldsList = val
    },
    SET_SESSIONS_LIST: (state, val) => {
      state.sessionsList = val
    },
    SET_SELECTED_GROUP_TYPE: (state, val) => {
      state.selectedGroupType = val
    },
    SET_SELECTED_GROUPS: (state, val) => {
      state.selectedGroups = val
    },
    REMOVE_SELECTED_GROUP: (state, groupId) => {
      const targetGroupIndex = state.selectedGroups.findIndex(item => item === groupId)
      state.selectedGroups.splice(targetGroupIndex, 1)
    },
    UPDATE_SELECTED_GROUPS_ORDER: state => {
      const newGroupsOrder = state.groupsList.filter(item => item.name !== 'Unassigned').map(item => item.id)
      state.selectedGroups = newGroupsOrder
    },
    SET_SELECTED_GROUP_FIELDS: (state, val) => {
      state.selectedGroupFields = val
    },
    SET_SELECTED_CARD_FIELDS: (state, val) => {
      state.selectedCardFields = val
    },
    SET_SELECTED_SESSIONS: (state, val) => {
      state.selectedSessions = val
    },
    SET_SELECTED_GROUP_SETTINGS: state => {
      const oldGroups = Object.keys(state.selectedGroupSettings)
      oldGroups.forEach(group => {
        if (!state.selectedGroups.includes(group)) {
          delete state.selectedGroupSettings[group]
        }
      })

      state.selectedGroups.forEach(group => {
        if (!state.selectedGroupSettings[group]) {
          Vue.set(state.selectedGroupSettings, group, { width: 400 })
        }
      })
    },
    SET_GROUP_WIDTH_SETTINGS: (state, { groupId, width }) => {
      if (groupId === 'unassigned') {
        state.unassignedColumnSettings.width = width
      } else {
        state.selectedGroupSettings[groupId].width = width
      }
    },
    SET_WIZARD_FILTERS: (state, val) => {
      state.wizardFilters = val
    },
    ADD_NEW_FILTER_ROW: state => {
      state.wizardFilters.children.push({
        type: '',
        field: '',
        operator: '',
        value: '',
      })
    },
    REMOVE_FILTER_ROW: (state, index) => {
      state.wizardFilters.children.splice(index, 1)
    },
    UPDATE_GROUPS_LIST_ON_DRAG_ADD: (state, e) => {
      const targetGroupIndex = state.groupsList.findIndex(item => item.id === e.to.id)
      if (state.selectedUsers.length) {
        const users = []
        state.selectedUsers.forEach(item => {
          // eslint-disable-next-line eqeqeq
          const user = store.getters['app-group-kanban-view/getAllUsers'].find(u => u.user_id == item.userId)
          if (user) users.push(user)
        })

        state.groupsList[targetGroupIndex].users.splice(e.newIndex, 0, ...users)
      } else {
        // eslint-disable-next-line eqeqeq
        const user = store.getters['app-group-kanban-view/getAllUsers'].find(u => u.user_id == e.item.id)
        state.groupsList[targetGroupIndex].users.splice(e.newIndex, 0, user)
      }
    },
    UPDATE_GROUPS_LIST_ON_DRAG_REMOVE: (state, e) => {
      if (state.selectedUsers.length) {
        state.selectedUsers.forEach(u => {
          const targetGroupIndex = state.groupsList.findIndex(item => item.id === u.groupId)
          const userIndex = state.groupsList[targetGroupIndex].users.findIndex(item => item.user_id === u.userId)
          state.groupsList[targetGroupIndex].users.splice(userIndex, 1)
        })
      } else {
        const targetGroupIndex = state.groupsList.findIndex(item => item.id === e.from.id)
        state.groupsList[targetGroupIndex].users.splice(e.oldIndex, 1)
      }
    },
    UPDATE_GROUPS_LIST_ON_DRAG_UPDATE: (state, e) => {
      if (state.selectedUsers.length) {
        state.selectedUsers.forEach(u => {
          const targetGroupIndex = state.groupsList.findIndex(item => item.id === u.groupId)
          const userIndex = state.groupsList[targetGroupIndex].users.findIndex(item => item.user_id === u.userId)
          const user = state.groupsList[targetGroupIndex].users.splice(userIndex, 1)[0]
          state.groupsList[targetGroupIndex].users.splice(e.newIndex, 0, user)
        })
      } else {
        const targetGroupIndex = state.groupsList.findIndex(item => item.id === e.from.id)
        const user = state.groupsList[targetGroupIndex].users.splice(e.oldIndex, 1)[0]
        state.groupsList[targetGroupIndex].users.splice(e.newIndex, 0, user)
      }
    },
    SET_CHANGE_LIST: (state, e) => {
      state.changeList = e
    },
    TOGGLE_SELECTED_USER: (state, { groupId, userId }) => {
      const selectedUserIndex = state.selectedUsers.findIndex(u => u.userId === userId)
      if (selectedUserIndex !== -1) {
        state.selectedUsers.splice(selectedUserIndex, 1)
      } else {
        state.selectedUsers.push({ groupId, userId })
      }
    },
    CLEAR_SELECTED_USERS: state => {
      state.selectedUsers = []
    },
    SET_SAVE_MODAL_MODE: (state, val) => {
      state.saveModalMode = val
    },
    SET_WIZARD_SETTINGS: (state, val) => {
      state.selectedGroupType = val.selected_group_type
      state.selectedGroups = val.selected_groups
      state.selectedGroupFields = val.selected_group_fields
      state.selectedCardFields = val.selected_application_fields
      state.selectedSessions = val.selected_semesters
      state.wizardFilters = val.wizard_filters
      state.isTableMode = val.is_table_mode
      if (val.selected_group_settings) {
        state.selectedGroupSettings = state.selectedGroups.reduce((res, curGroup) => {
          if (val.selected_group_settings[curGroup]?.width) {
            res[curGroup] = val.selected_group_settings[curGroup]
          } else {
            res[curGroup] = { width: 400 }
          }
          return res
        }, {})
      } else {
        state.selectedGroupSettings = state.selectedGroups.reduce((res, curGroup) => {
          res[curGroup] = { width: 400 }
          return res
        }, {})
      }
      state.unassignedColumnSettings = val.unassigned_column_settings?.width
        ? val.unassigned_column_settings
        : { width: 400 }
      // if (val.unassigned_column_settings?.width) {

      // } else {
      //   state.unassignedColumnSettings = { width: 400 }
      // }
    },
    RESET_WIZARD_SETTINGS: state => {
      state.selectedGroupType = null
      state.selectedGroups = []
      state.selectedGroupFields = []
      state.selectedCardFields = []
      state.selectedSessions = []
      state.selectedGroupSettings = {}
      state.unassignedColumnSettings = {
        width: 400,
      }
      state.wizardFilters = {
        children: [
          {
            type: '',
            field: '',
            operator: '',
            value: '',
          },
        ],
        logicalOperator: 'and',
      }
      state.isTableMode = 1
    },
    RESET_STATE: state => {
      Object.assign(state, getDefaultState())
    },
    SET_IS_STATE_CHANGED: (state, val) => {
      state.isStateChanged = val
    },
    SET_PARENT_DRAGGABLE_TABLE: (state, val) => {
      state.parentDraggableTable = val
    },
    SET_TABLE_COLUMNS: (state, val) => {
      state.tableColumns = val.reduce((arr, item) => {
        arr.push(item)
        return arr
      }, [''])
    },
    SET_IS_SAVE_BUTTON_DISABLED: (state, val) => {
      state.isSaveButtonDisabled = val
    },
  },
  actions: {
    async fetchWizardsList({ commit, rootGetters }) {
      try {
        const queryParams = {
          programId: rootGetters['verticalMenu/getDefaultProgram'],
        }
        const response = await axios.get('/auth/wizards', {
          params: queryParams,
        })

        commit('SET_WIZARDS_LIST', response.data.data)
        return response
      } catch (error) {
        return error
      }
    },
    async fetchWizard(ctx, wizardId) {
      try {
        const response = await fetchWizard(wizardId)

        return response.data.data
      } catch (error) {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Wizard',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return error
      }
    },
    async fetchGroupTypes({ commit }, queryParams) {
      try {
        const response = await axios.get('/auth/group-types-for-select', {
          params: queryParams,
        })

        commit('SET_GROUP_TYPES_LIST', response.data.data)
        return response
      } catch (error) {
        return error
      }
    },
    async fetchGroupTypeGroups({ commit }, queryParams) {
      try {
        const response = await axios.get('/auth/groups-for-select', {
          params: queryParams,
        })

        commit('SET_GROUP_TYPE_GROUPS_LIST', response.data.data)
        return response
      } catch (error) {
        return error
      }
    },
    async fetchSessions({ commit }, queryParams) {
      try {
        const response = await axios.get('/auth/sessions', {
          params: queryParams,
        })

        commit('SET_SESSIONS_LIST', response.data.data)
        return response
      } catch (error) {
        return error
      }
    },
    async fetchGroupFields({ commit }, queryParams) {
      try {
        const response = await axios.get('/auth/group-custom-fields-for-select', {
          params: queryParams,
        })

        commit('SET_GROUP_FIELDS_LIST', response.data.data)
        return response
      } catch (error) {
        return error
      }
    },
    async fetchPpaList({ commit }, queryParams) {
      try {
        const response = await axios.get('/auth/ppa/names', {
          params: queryParams,
        })

        commit('SET_CARD_FIELDS_LIST', response.data.data)
        return response
      } catch (error) {
        return error
      }
    },
    async fetchWizardApplications({ commit, rootGetters, state }) {
      try {
        commit('SET_IS_LOADING', true)
        commit('SET_SELECTED_GROUP_SETTINGS')
        const queryParams = {
          programId: rootGetters['verticalMenu/getDefaultProgram'],
          selected_group_type: state.selectedGroupType,
          selected_groups: state.selectedGroups,
          selected_semesters: state.selectedSessions,
          selected_group_fields: state.selectedGroupFields,
          selected_application_fields: state.selectedCardFields,
          filters: state.wizardFilters,
        }

        const response = await fetchWizardApplications(queryParams)

        commit('SET_TABLE_COLUMNS', state.selectedCardFields)
        commit('SET_GROUPS_LIST', response.data.data)
        return response
      } catch (error) {
        return error
      } finally {
        commit('SET_IS_LOADING', false)
      }
    },
    async createWizard({
      commit, state, rootGetters,
    }, wizardName) {
      try {
        const queryParams = {
          name: wizardName,
          program_id: rootGetters['verticalMenu/getDefaultProgram'],
          selected_group_type: state.selectedGroupType,
          selected_groups: state.selectedGroups,
          selected_group_fields: state.selectedGroupFields,
          selected_application_fields: state.selectedCardFields,
          selected_semesters: state.selectedSessions,
          filters: state.wizardFilters,
          is_table_mode: state.isTableMode,
          selected_group_settings: state.selectedGroupSettings,
          unassigned_column_settings: state.unassignedColumnSettings,
        }

        commit('SET_IS_LOADING', true)
        const response = await createWizard(queryParams)

        commit('SET_IS_STATE_CHANGED', false)
        return response
      } catch (error) {
        return error
      } finally {
        commit('SET_IS_LOADING', false)
      }
    },
    async updateWizard({
      commit, state, rootGetters,
    }, wizardName) {
      try {
        const queryParams = {
          id: state.selectedWizard.id,
          name: wizardName,
          program_id: rootGetters['verticalMenu/getDefaultProgram'],
          selected_group_type: state.selectedGroupType,
          selected_groups: state.selectedGroups,
          selected_group_fields: state.selectedGroupFields,
          selected_application_fields: state.selectedCardFields,
          selected_semesters: state.selectedSessions,
          filters: state.wizardFilters,
          is_table_mode: state.isTableMode,
          selected_group_settings: state.selectedGroupSettings,
          unassigned_column_settings: state.unassignedColumnSettings,
        }

        commit('SET_IS_LOADING', true)
        const response = await updateWizard(queryParams)
        commit('SET_IS_STATE_CHANGED', false)
        return response
      } catch (error) {
        return error
      } finally {
        commit('SET_IS_LOADING', false)
      }
    },
    async applyAssignments({
      state, rootGetters, commit, dispatch,
    }) {
      try {
        commit('SET_IS_SAVE_BUTTON_DISABLED', true)
        const assignmentsData = state.changeList
        const queryParams = {
          program_id: rootGetters['verticalMenu/getDefaultProgram'],
          sessions: state.selectedSessions,
          data: assignmentsData,
        }

        const response = await axios.post('/auth/groups-mass-assign', queryParams)

        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: response.data.message,
            icon: 'ThumbsUpIcon',
            variant: 'info',
          },
        })
        commit('SET_IS_SAVE_BUTTON_DISABLED', false)
        return response
      } catch (error) {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong, the page will be refreshed',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        await dispatch('fetchWizardApplications')
        commit('SET_IS_SAVE_BUTTON_DISABLED', false)
        return error
      }
    },
    async removeSelectedGroup({ commit, dispatch }, groupId) {
      commit('REMOVE_SELECTED_GROUP', groupId)
      commit('REMOVE_GROUP_FROM_GROUP_LIST', groupId)

      await dispatch('fetchWizardApplications')
    },
    async deleteWizard(ctx, wizardId) {
      try {
        const response = await axios.delete(`/auth/wizards/${wizardId}`)
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: response.data.message,
            icon: 'ThumbsUpIcon',
            variant: 'info',
          },
        })
        return response
      } catch (error) {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error deleting Wizard',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return error
      }
    },
  },
}
