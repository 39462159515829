<template>
  <tr
    @click="toggleSelect(groupId, user.user_id)"
  >
    <td
      scope="row"
    >
      <b-form-checkbox
        :checked="isSelected"
        :value="true"
        @change="toggleSelect(groupId, user.user_id)"
      />
    </td>
    <template v-for="(value, key) in user">
      <td
        v-if="!['application_id', 'user_id'].includes(key)"
        :key="key"
        scope="row"
      >
        {{ value }}
      </td>
    </template>
  </tr>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BFormCheckbox,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    groupId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      selectedUsers: 'app-group-kanban-view/getSelectedUsers',
    }),
    isSelected() {
      return this.selectedUsers.findIndex(u => u.userId === this.user.user_id && u.groupId === this.groupId) !== -1
    },
  },
  methods: {
    toggleSelect(groupId, userId) {
      this.$store.commit('app-group-kanban-view/TOGGLE_SELECTED_USER', { groupId, userId })
    },
  },
}
</script>

<style lang="scss" scoped>
td {
  padding: 0.72rem !important;
}
</style>
