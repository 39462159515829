<template>

  <validation-observer
    ref="wizardSettingsRules"
    tag="form"
  >
    <b-card
      no-body
      class="px-1"
    >
      <app-collapse>
        <app-collapse-item
          title
          :is-visible="true"
        >
          <template #header>
            <h3
              class="d-flex align-items-center mb-0"
            >
              <feather-icon
                icon="SettingsIcon"
                size="18"
                class="mr-1"
              />
              Wizard Settings
            </h3>
            <b-button
              class="ml-1"
              variant="primary"
              @click.stop="showFiltersModal"
            >Add Filters</b-button>
          </template>
          <b-overlay
            id="overlay-background"
            :show="isLoading"
            variant="white"
            opacity="1"
            rounded="sm"
          >
            <b-row>
              <b-col
                cols="4"
                class="mb-1"
              >
                <b-form-group
                  label="Group Type"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Group Type"
                    rules="required"
                  >
                    <v-select
                      v-model="selectedGroupType"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="groupTypesList"
                      :clearable="false"
                      label="text"
                      :reduce="option => option.value"
                      placeholder="Select Group Type"
                      @input="selectGroupType"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                cols="4"
                class="mb-1"
              >
                <b-form-group
                  label="Groups"
                >
                  <v-select
                    v-model="selectedGroups"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="groupTypeGroupsList"
                    label="name"
                    :reduce="option => option.id"
                    :close-on-select="false"
                    :deselect-from-dropdown="true"
                    placeholder="Select Groups"
                    multiple
                    @input="selectGroups"
                  />
                </b-form-group>
              </b-col>

              <b-col
                cols="4"
                class="mb-1"
              >
                <b-form-group
                  label="Group Fields"
                >
                  <v-select
                    v-model="selectedGroupFields"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="groupFieldsList"
                    placeholder="Select Group Fields"
                    multiple
                    label="label"
                    :close-on-select="false"
                    :deselect-from-dropdown="true"
                    :reduce="option => option.id"
                  />
                </b-form-group>
              </b-col>

              <b-col
                cols="4"
                class="mb-1"
              >
                <b-form-group
                  label="Card Fields"
                >
                  <v-select
                    v-model="selectedCardFields"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="cardFieldsList"
                    placeholder="Select Card Fields"
                    multiple
                    :close-on-select="false"
                    :deselect-from-dropdown="true"
                    label="name"
                  />
                </b-form-group>
              </b-col>

              <b-col
                cols="4"
                class="mb-1"
              >
                <b-form-group
                  label="Semesters"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Semesters"
                    rules="required"
                  >
                    <v-select
                      v-model="selectedSessions"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="sessionsList"
                      :clearable="false"
                      label="name"
                      :reduce="option => option.id"
                      :close-on-select="false"
                      :deselect-from-dropdown="true"
                      placeholder="Select Semesters"
                      multiple
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <hr>

            <b-row>
              <b-col
                cols="12"
                class="d-flex align-items-center justify-content-end mt-2"
              >
                <b-button
                  class="ml-1"
                  variant="outline-primary"
                >
                  <span>Cancel</span>
                </b-button>
                <b-button
                  class="ml-1"
                  variant="primary"
                  :disabled="isSaveButtonDisabled"
                  @click="fetchWizardApplications"
                >
                  <span>Apply</span>
                </b-button>
              </b-col>
            </b-row>
          </b-overlay>
        </app-collapse-item>
      </app-collapse>

      <wizard-filters-modal />
    </b-card>
  </validation-observer>
</template>

<script>
import { mapGetters } from 'vuex'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import {
  BCard, BRow, BCol, BButton, BFormGroup, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import WizardFiltersModal from '@/views/admin/group/group-assign-wizard/kanban-view/wizard-filters-modal/WizardFiltersModal.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    AppCollapse,
    AppCollapseItem,

    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BOverlay,

    vSelect,
    WizardFiltersModal,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    ...mapGetters({
      programId: 'verticalMenu/getDefaultProgram',
      selectedWizard: 'app-group-kanban-view/getSelectedWizard',
      groupTypesList: 'app-group-kanban-view/getGroupTypesList',
      groupTypeGroupsList: 'app-group-kanban-view/getGroupTypeGroupsList',
      groupFieldsList: 'app-group-kanban-view/getGroupFieldsList',
      cardFieldsList: 'app-group-kanban-view/getCardFieldsList',
      sessionsList: 'app-group-kanban-view/getSessionsList',
      wizardFilters: 'app-group-kanban-view/getWizardFilters',
      isLoading: 'app-group-kanban-view/getIsWizardSettingsLoading',
      isSaveButtonDisabled: 'app-group-kanban-view/getIsSaveButtonDisabled',
    }),
    selectedGroupType: {
      get() {
        return this.$store.getters['app-group-kanban-view/getSelectedGroupType']
      },
      set(val) {
        this.$store.commit('app-group-kanban-view/SET_IS_STATE_CHANGED', true)
        this.$store.commit('app-group-kanban-view/SET_SELECTED_GROUP_TYPE', val)
      },
    },
    selectedGroups: {
      get() {
        return this.$store.getters['app-group-kanban-view/getSelectedGroups']
      },
      set(val) {
        this.$store.commit('app-group-kanban-view/SET_IS_STATE_CHANGED', true)
        this.$store.commit('app-group-kanban-view/SET_SELECTED_GROUPS', val)
      },
    },
    selectedGroupFields: {
      get() {
        return this.$store.getters['app-group-kanban-view/getSelectedGroupFields']
      },
      set(val) {
        this.$store.commit('app-group-kanban-view/SET_IS_STATE_CHANGED', true)
        this.$store.commit('app-group-kanban-view/SET_SELECTED_GROUP_FIELDS', val)
      },
    },
    selectedCardFields: {
      get() {
        return this.$store.getters['app-group-kanban-view/getSelectedCardFields']
      },
      set(val) {
        this.$store.commit('app-group-kanban-view/SET_IS_STATE_CHANGED', true)
        this.$store.commit('app-group-kanban-view/SET_SELECTED_CARD_FIELDS', val)
      },
    },
    selectedSessions: {
      get() {
        return this.$store.getters['app-group-kanban-view/getSelectedSessions']
      },
      set(val) {
        this.$store.commit('app-group-kanban-view/SET_IS_STATE_CHANGED', true)
        this.$store.commit('app-group-kanban-view/SET_SELECTED_SESSIONS', val)
      },
    },
  },
  watch: {
    async programId(val, prevVal) {
      if (val !== prevVal) {
        await this.initWizardSettings()
      }
    },
  },
  async created() {
    await this.initWizardSettings()
  },
  methods: {
    async initWizardSettings() {
      try {
        this.$store.commit('app-group-kanban-view/SET_IS_WIZARD_SETTINGS_LOADING', true)
        await this.fetchGroupTypes()
        await this.fetchGroupTypeGroups()
        await this.fetchSessions()
        await this.fetchPpaList()
        await this.fetchGroupFields()
      } finally {
        this.$store.commit('app-group-kanban-view/SET_IS_WIZARD_SETTINGS_LOADING', false)
      }
    },
    async fetchGroupTypes() {
      const queryParams = {
        programId: this.programId,
      }
      await this.$store.dispatch('app-group-kanban-view/fetchGroupTypes', queryParams)
    },
    async fetchGroupTypeGroups() {
      const queryParams = {
        exceptProgramType: 'Program',
        programId: this.programId,
        typeId: this.selectedGroupType,
      }
      await this.$store.dispatch('app-group-kanban-view/fetchGroupTypeGroups', queryParams)
    },
    async fetchGroupFields() {
      const queryParams = {
        programId: this.programId,
        group_id: this.selectedGroups,
      }
      await this.$store.dispatch('app-group-kanban-view/fetchGroupFields', queryParams)
    },
    async fetchSessions() {
      const queryParams = {
        programId: this.programId,
      }
      await this.$store.dispatch('app-group-kanban-view/fetchSessions', queryParams)
    },
    async fetchPpaList() {
      const queryParams = {
        programId: this.programId,
      }
      await this.$store.dispatch('app-group-kanban-view/fetchPpaList', queryParams)
    },
    async selectGroupType() {
      this.resetSelectedGroups()
      this.resetSelectedGroupFields()
      await this.fetchGroupTypeGroups()
      // Select all groups
      const selectedGroups = this.groupTypeGroupsList.map(item => item.id)
      this.$store.commit('app-group-kanban-view/SET_SELECTED_GROUPS', selectedGroups)
      this.selectGroups()
    },
    async selectGroups() {
      this.resetSelectedGroupFields()
      await this.fetchGroupFields()
    },
    resetSelectedGroups() {
      this.$store.commit('app-group-kanban-view/SET_SELECTED_GROUPS', [])
    },
    resetSelectedGroupFields() {
      this.$store.commit('app-group-kanban-view/SET_SELECTED_GROUP_FIELDS', [])
    },
    showFiltersModal() {
      this.$bvModal.show('wizard-filters-modal')
    },
    fetchWizardApplications() {
      return new Promise((resolve, reject) => {
        this.$refs.wizardSettingsRules.validate().then(success => {
          if (success) {
            resolve(true)
            this.$store.dispatch('app-group-kanban-view/fetchWizardApplications')
          } else {
            window.scrollTo(0, 0)
            reject()
          }
        })
      })
    },
  },

}
</script>

<style>

</style>
