<template>
  <ValidationObserver
    ref="saveWizardModalObserver"
  >
    <b-modal
      id="save-wizard-modal"
      ref="save-wizard-modal"
      :title="title"
      centered
      no-close-on-backdrop
      :ok-title="title"
      cancel-variant="outline-secondary"
      size="sm"
      class="p-0"
      @show="setModalData"
      @ok="saveWizard"
    >
      <b-overlay
        id="overlay-background"
        :show="isLoading"
        variant="white"
        opacity="1"
        rounded="sm"
      >
        <b-form-group
          label="Wizard Name"
        >
          <ValidationProvider
            #default="{ errors }"
            name="Wizard Name"
            rules="required"
          >
            <b-form-input
              v-model="name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-overlay>
    </b-modal>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BModal, BFormGroup, BFormInput, BOverlay,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BOverlay,

    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      name: '',
      required,
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'app-group-kanban-view/getIsLoading',
      saveModalMode: 'app-group-kanban-view/getSaveModalMode',
      selectedWizardName: 'app-group-kanban-view/getSelectedWizardName',
    }),
    title() {
      return this.saveModalMode === 'save' ? 'Save' : 'Save As'
    },
  },
  methods: {
    setModalData() {
      if (this.selectedWizardName) {
        this.name = this.selectedWizardName
      } else {
        this.name = 'Wizard name'
      }
    },
    saveWizard(bvModalEvt) {
      bvModalEvt.preventDefault()

      return new Promise((resolve, reject) => {
        this.$refs.saveWizardModalObserver.validate().then(success => {
          if (success) {
            resolve(true)
            if (this.saveModalMode === 'saveAs') {
              this.createWizard()
            } else {
              this.updateWizard()
            }
          } else {
            reject()
          }
        })
      })
    },
    async createWizard() {
      await this.$store.dispatch('app-group-kanban-view/createWizard', this.name)
      this.closeModal()
    },
    async updateWizard() {
      await this.$store.dispatch('app-group-kanban-view/updateWizard', this.name)
      this.$bvModal.hide('save-wizard-modal')
    },
    closeModal() {
      this.$bvModal.hide('save-wizard-modal')
      this.$router.push({ name: 'admin-group-wizard-list' })
    },
  },
}
</script>

<style>

</style>
